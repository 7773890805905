import React, { Fragment } from "react";
import { Form } from "react-bootstrap";

export const DisplayFileIcons = ({ handleFileChange }) => {

    return (
        <div className="mb-4 mt-4">
          {/* Hidden File Input for Attachments */}
          <Form.Group className="ms-4" controlId="attachment-upload" style={{ display: 'none' }}>
            <Form.Control
              type="file"
              multiple
              onChange={handleFileChange}
              id="attachment-file-input"
            />
          </Form.Group>

          {/* Hidden File Input for Images */}
          <Form.Group className="ms-4" controlId="image-upload" style={{ display: 'none' }}>
            <Form.Control
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              id="image-file-input"
            />
          </Form.Group>

          {/* Attachment Icon */}
          <i
            className="fe fe-paperclip text-muted ms-3 cursor-pointer"
            onClick={() => document.getElementById('attachment-file-input').click()}
            style={{ fontSize: '1.5rem' }}
          ></i>

          {/* Image Icon */}
          <i
            className="fe fe-image text-muted ms-3 cursor-pointer"
            onClick={() => document.getElementById('image-file-input').click()}
            style={{ fontSize: '1.5rem' }}
          ></i>
        </div>
    );
}