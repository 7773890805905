import React from 'react';
import ReusableTable from '../../common/table/ReusableTable';

const StudentTable = ({ students }) => {
  const columns = [
    { header: '#', accessor: '' },
    { header: 'Full Name', accessor: 'fullName' },
    { header: 'Course', accessor: 'course' },
    { header: 'Email', accessor: 'email' },
    { header: 'Mobile', accessor: 'mobile' },
    { header: 'Date', accessor: 'date' },
  ];

  const actions = [
    { label: 'View/Edit', icon: null }
  ];

  return <ReusableTable data={students} columns={columns} actions={actions} tableName="Student" />;
};

export default StudentTable;
