import React from 'react';
import TableWrapper from '../../common/table/TableWrapper';
import StudentTable from './StudentTable'; // Your existing courseTable component
import { useFetchData } from 'hooks/useFetchData';

const StudentList = () => {
  const { data } = useFetchData('/trainer/students');
  const tabs = [
    { eventKey: 'all', label: 'All', component: <StudentTable students={data?.data?.data || []} /> },
  ];

  return (
    <TableWrapper
      title="All Students"
      breadcrumbs={[
        { label: 'Home', link: '#' },
        { label: 'All', active: true },
      ]}
      //buttonLink="#"
      //buttonText="Add New Students"
      tabs={tabs}
      defaultTabKey="all"
    />
  );
};

export default StudentList;
