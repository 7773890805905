import React from "react";
import { Form, Image } from "react-bootstrap";

export const DisplayFileList = ({ handleFileRemove, attachments }) => {

    return (
        <div className="mb-4 mt-4">
          {attachments.length > 0 && (
            <div className="mb-3">
              <Form.Label>Selected Files/Images</Form.Label>
              <div className="d-flex flex-wrap">
                {attachments.map((file, index) => (
                  <div key={index} className="me-3 mb-3" style={{ position: 'relative' }}>
                    {file?.type?.startsWith('image/') ? (
                      <Image
                        src={URL.createObjectURL(file)}
                        alt={file?.name}
                        thumbnail
                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                      />
                    ) : (
                      <div className="file-preview" style={{ width: '100px', height: '100px', border: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>{file?.name}</span>
                      </div>
                    )}
                    {/* Remove File Button */}
                    <button
                      type="button"
                      className="btn-close"
                      style={{ position: 'absolute', top: '0', right: '0' }}
                      onClick={() => handleFileRemove(index)}
                    ></button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
    );
}