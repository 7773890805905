// import node module libraries
import { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { useEffect } from 'react';
import AileadButton from 'components/marketing/common/button/AileadButton';

const PayoutForm = () => {
	const [ formValue, setFormValue] = useState({});
	const { response, loading, error, postData } = usePostData('/trainer/payout');
	const { data } = useFetchData('/user/balance');

	const handleChange = (e) => {
        const { name, value } = e.target;

        setFormValue({
            ...formValue,
            [name]: value
        })
	};

    const handleSubmit = () => {
        postData({ amount: formValue.amount })
	};

    useEffect(() => { 
		if(response?.status === "Success") {
			// test for response
			console.log("DATA BASES >> ", response?.status)
		}
    }, [response])

	return (
		<Form>
			{/* Card */}
			<Card className="mb-3 ">
				<Card.Header className="border-bottom px-4 py-3">
				  <h4 className="mb-0">Request Payout</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					<Form.Group className="mb-3">
						<Form.Label htmlFor="courseTitle">Payout Amount 
                            <Form.Text className='text-danger'>*</Form.Text>
                        </Form.Label>
						<Form.Control
							type="text"
							placeholder="Payout Amount"
							name="amount"
							value={formValue.amount}
							onChange={handleChange}
						/>
						<Form.Text className="text-muted">
							Amount shoud be a maximum of: 3000
						</Form.Text>
					</Form.Group>
					{error?.title && (
						<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
							{ error?.title[0] }
						</Form.Text>
					)}
				</Card.Body>
			</Card>
			<AileadButton variant="primary" type="button" onClick={handleSubmit} loading={loading} text="Submit" />
		</Form>
	);
};
export default PayoutForm;
