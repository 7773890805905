// import node module libraries
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Dropdown, Table, Image, Badge } from 'react-bootstrap';

// import custom components
import ApexCharts from 'components/elements/charts/ApexCharts';
import StatTopIcon from 'components/marketing/common/stats/StatTopIcon';
import { useFetchData } from 'hooks/useFetchData';

import {
	getEarningsChartOptions
} from 'data/charts/ChartData';
import { formatNumberWithCommas } from 'helper/utils';

const EarningCard = () => {
    const {data: earnings} = useFetchData('/trainer/earnings');
    const {data: popularCourses} = useFetchData('/trainer/popular/courses');

	const SERIES_DATA = earnings && earnings?.data?.series?.data?.map((item, index) => {
		return parseInt(item);
	});
	// Function to calculate the maximum value of the earning
	 // Chart options
	  
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	const ActionMenu = () => {
		return (
			<div>
				<Dropdown>
					<Dropdown.Toggle as={CustomToggle}>
						<i className="fe fe-more-vertical text-muted"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu align={'end'}>
						<Dropdown.Header>SETTINGS</Dropdown.Header>
						<Dropdown.Item eventKey="1">
							<i className="fe fe-edit dropdown-item-icon"></i> Edit
						</Dropdown.Item>
						<Dropdown.Item eventKey="2">
							<i className="fe fe-trash dropdown-item-icon"></i> Remove
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	};

	const ChartActionMenu = () => {
		return (
			<div>
				<Dropdown>
					<Dropdown.Toggle as={CustomToggle}>
						<i className="fe fe-more-vertical text-muted"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu align="end">
						<Dropdown.Header>SETTINGS</Dropdown.Header>
						<Dropdown.Item eventKey="1">30 Days</Dropdown.Item>
						<Dropdown.Item eventKey="2">2 Months</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	};

	return (
		<>
			<Card className="mb-4 pb-1">
				<Card.Header className="border-0 ">
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Earnings</h3>
						<p className="mb-0">
							You have full control to manage your own account setting.
						</p>
					</div>
				</Card.Header>
			</Card>
			<Row>
				<Col xl={12} lg={12} md={12} className="mb-4">
					<Card>
						<Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
							<div>
								<h4 className="mb-0">Earnings</h4>
							</div>
							<div>
								<ChartActionMenu />
							</div>
						</Card.Header>
						<Card.Body>
							<Row>
								<Col xl={3} lg={4} md={12} sm={12} className="mb-3 mb-lg-0">
									<div>
										<StatTopIcon
											title="Your total earnings"
											value={`${earnings?.data?.options?.symbol}${earnings?.data?.allTime}`}
											iconName="shopping-cart"
											colorVariant="success"
											flat
										/>
									</div>
								</Col>
								{/* Earning chart */}
								<Col xl={9} lg={8} md={12} sm={12}>
									{earnings?.data?.series?.data && 
										(<ApexCharts
											options={getEarningsChartOptions(SERIES_DATA)}
											series={[earnings.data && earnings.data.series]}
											
											type="line"
										/>)
									}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col lg={4} md={12} sm={12} className="mb-4 mb-lg-0">
					<StatTopIcon
						title="Earning this month"
						value={`${earnings?.data?.options?.symbol}${earnings?.data?.thisMonth}`}
						iconName="folder"
						colorVariant="primary"
					/>
				</Col>
				<Col lg={4} md={12} sm={12} className="mb-4 mb-lg-0">
					<StatTopIcon
						title="Account Balance"
						value={`${earnings?.data?.options?.symbol}${earnings?.data?.balance}`}
						iconName="shopping-bag"
						colorVariant="danger"
					/>
				</Col>
				<Col lg={4} md={12} sm={12}>
					<StatTopIcon
						title="Life Time Sales"
						value={`${earnings?.data?.options?.symbol}${earnings?.data?.allTime}`}
						iconName="send"
						colorVariant="warning"
					/>
				</Col>
			</Row>

			<Card className="mt-4">
				<Card.Header>
					<h3 className="mb-0 h4">Best Selling Courses</h3>
				</Card.Header>
				<Card.Body className="p-0">
					<Table responsive hover className="mb-0 text-nowrap table-centered">
						<thead className="table-light">
							<tr>
								<th scope="col">Courses</th>
								<th scope="col">Sales</th>
								<th scope="col">Amount</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{popularCourses?.data?.popularCourses?.map((item, index) => {
								return (
									<tr key={item.id + index}>
										<td>
											<Link to="#">
												<div className="d-lg-flex align-items-center">
													<Image
														src={item.image}
														alt=""
														className="rounded img-4by3-lg"
													/>
													<h5 className="mb-0 ms-lg-3 mt-lg-0 mt-2 text-primary-hover">
														{item.title}
													</h5>
												</div>
											</Link>
										</td>
										<td>{item.sales}</td>
										<td>{item?.amount?.symbol}{formatNumberWithCommas(item?.amount?.amount)} </td>
										
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</>
	);
};

export default EarningCard;
