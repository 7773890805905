// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Image, Modal, Form, Dropdown } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';
import Ratings from 'components/marketing/common/ratings/Ratings';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { numberWithCommas } from 'helper/utils';
import { usePaystack } from 'hooks/usePaystack';
import { usePaymentOrder } from 'hooks/usePaymentOrder';
import { useSelector } from 'react-redux';
import { usePostData } from 'hooks/usePostData';
import { notifyError, notifySuccess } from 'helper/utils';
import { useNavigate } from 'react-router-dom';
import AddNewCategoryPopup from 'components/dashboard/courses/AddNewCategoryPopup';

    const CoursePurchaseModal = ({show, handleClose, data}) => {
        const { postData } = usePostData("/payment/enroll-course");
        const [quantity, setQuantity] = useState(1);
        const PayStackPayment = usePaystack();
        const PayPalPayment = usePaymentOrder();
        const {user} = useSelector(state => state.auth);
        const courseData = {
            id: data?.id,
            price: data?.price,
            title: data?.title,
            quantity: quantity
        };
        
        const incrementQuantity = () => {
          setQuantity(prevQuantity => prevQuantity + 1);
        };
        
        const decrementQuantity = () => {
          if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
          }
        };

        function handlePayment (paystackResponse) {
            postData({
                identifier: 'business_course_purchase', 
                reference: paystackResponse?.reference, 
                course: JSON.stringify(courseData)
            });
            handleClose();
            notifySuccess("Course Payment Successful!");
        };
    
        function handlePaystackError() {
            notifyError("Payment Failed!");
        }

        return (
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Purchase Deatils</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <Form.Group className="mb-3" controlId="formaddnewlecture">
                        <Form.Label>Course Price </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Add Lesson Title"
                            className='mb-4'
                            value={`${data?.price?.symbol}${numberWithCommas(quantity * data?.price?.amount)}`}
                        />
                        <Form.Label>Quantity </Form.Label>
                        <div className="d-flex align-items-center mb-5">
                            <AileadButton type="button" variant="outline-primary" onClick={decrementQuantity} text="-" />
                            <input
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(parseInt(e.target.value))}
                                className="form-control mx-2"
                                style={{ width: '70px' }}
                            />
                            <AileadButton type="button" variant="outline-primary" onClick={incrementQuantity} text="+" />
                        </div>

                        {data?.price?.currency === "NGN" ? 
                            <PayStackPayment
                                onError={handlePaystackError} 
                                onSuccess={handlePayment} 
                                amount={quantity * data?.price?.amount} 
                                email={user?.email} 
                            /> :
                            <PayPalPayment 
                                amount={quantity * data?.price?.amount} 
                                identifier="business_course_purchase" 
                                course={courseData} 
                            />
                        }
                    </Form.Group>
                </Modal.Body>
            </Modal>
        )
    }


    const CourseSubscriptionModal = ({show, handleClose, data}) => {
        const [quantity, setQuantity] = useState(1);
        const PayStackPayment = usePaystack();
        const PayPalPayment = usePaymentOrder();
        const {user} = useSelector(state => state.auth);
        const planData = {
            title: data.title,
			description: data.description,
			features: data.features,
			type: "yearly",
			price: data?.prices,
        };
        
        const { postData } = usePostData("/payment/start-sub-trial");
        const incrementQuantity = () => {
          setQuantity(prevQuantity => prevQuantity + 1);
        };
        
        const decrementQuantity = () => {
          if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
          }
        };

        function handlePayment (paystackResponse) {
            postData({
                identifier: 'business_subscription_purchase', 
                reference: paystackResponse?.reference, 
                plan: JSON.stringify(planData),
                amount: quantity * data?.priceYearly?.amount,
                currency: data?.priceYearly?.code,
                quantity: quantity,
                paymentMethod: 'paystack',
            });
            notifySuccess("Course Payment Successful!")
            handleClose();
        };
    
        function handlePaystackError() {
            notifyError("Payment Failed!");
        }

        return (
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Purchase Deatils</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <Form.Group className="mb-3" controlId="formaddnewlecture">
                        <Form.Label>Course Price </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Add Lesson Title"
                            className='mb-4'
                            value={`${data?.priceYearly?.symbol} ${numberWithCommas(quantity * data?.priceYearly?.amount)}`}
                        />
                        <Form.Label>Quantity </Form.Label>
                        <div className="d-flex align-items-center mb-5">
                            <AileadButton type="button" variant="outline-primary" onClick={decrementQuantity} text="-" />
                            <input
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(parseInt(e.target.value))}
                                className="form-control mx-2"
                                style={{ width: '70px' }}
                            />
                            <AileadButton type="button" variant="outline-primary" onClick={incrementQuantity} text="+" />
                        </div>

                        {data?.priceYearly?.code === "NGN" ? 
                            <PayStackPayment
                                onError={handlePaystackError} 
                                onSuccess={handlePayment} 
                                amount={quantity * data?.priceYearly?.amount} 
                                email={user?.email} 
                            /> :
                            <PayPalPayment 
                                amount={quantity * data?.priceYearly?.amount} 
                                identifier="business_subscription_purchase"
                                plan={planData}
                            />
                        }
                    </Form.Group>
                </Modal.Body>
            </Modal>
        )
    }

    export const CourseInitialColumn = ({course}) => {
        return (
            <Link className="text-inherit" to={"#"}>
                <div className="d-lg-flex align-items-center">
                    <div>
                        <Image
                            src={course.image}
                            alt=""
                            className="img-4by3-lg rounded"
                        />
                    </div>
                    <div className="ms-lg-3 mt-2 mt-lg-0">
                        <h4 className="mb-1 text-primary-hover">{course.title}</h4>
                        <div className="text-warning me-1 mb-1">
                            <Ratings rating={course.rating} size="0.92rem" /> ({course.reviews})
                        </div>
                        <span className="text-inherit">
                            Last Update: {course.lastUpdated}
                        </span>
                        <div className="text-inherit">
                            Price: {" "} {course?.price?.symbol}{course.price?.amount}
                        </div>
                        {/* <div className="text-inherit">
                            Duration: {" "} {course?.formatedDuration}
                        </div> */}
                    </div>
                </div>
            </Link>
        )
    }

	export const PurchaseMenu = ({value}) => {
        const [show, setShow] = useState(false);

        const handleShow = () => {
            setShow(true);
        }

        const handleClose = () => {
            setShow(false);
        }

		return (
            <Fragment>
                <AileadButton text="Purchase" type="button" onClick={handleShow} />
                <CoursePurchaseModal handleClose={handleClose} show={show} data={value} />
            </Fragment>
           
		);
	};

    export const SubscriptionMenu = ({value}) => {
        const [show, setShow] = useState(false);

        const handleShow = () => {
            setShow(true);
        }

        const handleClose = () => {
            setShow(false);
        }

		return (
            <Fragment>
                <AileadButton text="Subscribe" type="button" onClick={handleShow} />
                <CourseSubscriptionModal handleClose={handleClose} show={show} data={value} />
            </Fragment>
		);
	};

    export const InstructorData = ({ row }) => {
        return (
            <div className="d-flex align-items-center">
                <Image
                    src={row.instructor?.avatar}
                    alt=""
                    className="rounded-circle avatar-lg me-2"
                />
                <h5 className="mb-0">{row?.instructor?.firstName}{' '}{row?.instructor?.lastName}</h5>
            </div>
        )
    }

    export const CourseStatus = ({value}) => {
        
        return (
            <Fragment>
                
                <DotBadge
                    bg={
                        value?.status === 'Unpublished'
                            ? 'danger'
                            : value.status === 'Published'
                            ? 'success'
                            : ''
                    }
                ></DotBadge>
                {value.status.charAt(0).toUpperCase() + value.status.slice(1)}
            </Fragment>
        );
    };

    export const PurchaseWithSubcriptionCredit = ({course}) => {
        const [quantity, setQuantity] = useState(1);

        const [show, setShow] = useState(false);

        const handleShow = () => {
            setShow(true);
        }

        const handleClose = () => {
            setShow(false);
        }
        
        const incrementQuantity = () => {
          setQuantity(prevQuantity => prevQuantity + 1);
        };
        
        const decrementQuantity = () => {
          if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
          }
        };

        const {response, postData, loading} = usePostData("/business/enroll/subcription");
        
        const handlPurchaseWithSubcription = async () => {
            const myData = {
                id: course?.id,
                quantity,
                amount: course?.price.amount,
                currency: course?.price.currency,
            }

            await postData({
                data: myData,
            });

            handleClose();
        }

        useEffect(() => {
            if(response?.status === "Success" && response?.data?.status) {
                notifySuccess(response?.data?.message)
            }
        }, [response])

		return (
            <Fragment>
                <AileadButton text="Purchase with Subcription" type="button" onClick={handleShow} />
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Subscribe to Course using Subcription Credit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <Form.Group className="mb-3" controlId="formaddnewlecture">
                            <Form.Label>Course Price </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Add Lesson Title"
                                className='mb-4'
                                value={`${course?.price?.symbol} ${numberWithCommas(quantity * course?.price?.amount)}`}
                            />
                            <Form.Label>Quantity </Form.Label>
                            <div className="d-flex align-items-center mb-5">
                                <AileadButton type="button" variant="outline-primary" onClick={decrementQuantity} text="-" />
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                                    className="form-control mx-2"
                                    style={{ width: '70px' }}
                                />
                                <AileadButton type="button" variant="outline-primary" onClick={incrementQuantity} text="+" />
                            </div>
                            <AileadButton type="button" variant="outline-primary" loading={loading} onClick={handlPurchaseWithSubcription} text="Subscribe To Course" />
                        </Form.Group>
                    </Modal.Body>
                </Modal>
            </Fragment>
		);
	};

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to="#"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

    export const EditableMenu = ({value, removeCourseLoading, handleDeleteCourse, publishLoading, handlePublish}) => {
		const navigate = useNavigate();
        const [remove, setRemove] = useState(false);
        const [publish, setPublish] = useState(false);
        const {user} = useSelector(state => state.auth);

		return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                        <MoreVertical size="15px" className="text-secondary" />
                    </Dropdown.Toggle>
                    {console.log("MY DATA >> ", user, "  ", value)}
                    <Dropdown.Menu align="end">
                        <Dropdown.Item eventKey="1" onClick={() => navigate('/dashboard/course/update/'+value.slug)}>
                            View/Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setPublish(true)}>
                            { value.status === "Published" ? "Un-Publish" : "Publish" }
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setRemove(true)}>
                            Move to Trash
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <DeleteConfirmationModal 
                    show={remove}
                    handleClose={() => setRemove(false)}
                    handleConfirm={handleDeleteCourse}
                    loading={removeCourseLoading}
                    item={value ? value.title: ""}
                />

                <InfoModal 
                    show={publish}
                    handleClose={() => setPublish(false)}
                    handleConfirm={handlePublish}
                    loading={publishLoading}
                    infoText={"Are you ready to publish your course? \n Make sure your course is ready before publishing"}
                    infoTitle={"Course Publishing!"}
                />
            </>
		);
	};

    export const TrashedMenu = ({value, removeCourseLoading, handleDeleteCourse, restoreLoading, handleRestore}) => {
        const [remove, setRemove] = useState(false);
        const [restore, setRestore] = useState(false);
		return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                        <MoreVertical size="15px" className="text-secondary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Item eventKey="1" onClick={() => setRestore(true)}>
                            Restore
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setRemove(true)}>
                            Permanent Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <DeleteConfirmationModal 
                    show={remove}
                    handleClose={() => setRemove(false)}
                    handleConfirm={handleDeleteCourse}
                    loading={removeCourseLoading}
                    item={value ? value.title: ""}
                />

                <InfoModal 
                    show={restore}
                    handleClose={() => setRestore(false)}
                    handleConfirm={handleRestore}
                    loading={restoreLoading}
                    infoText={"Are you sure you want to restore back trashed course?"}
                    infoTitle={"Restore Trashed"}
                />
            </>
		);
	};


    export const PublishedMenu = ({publishLoading, handlePublish}) => {
        const [publish, setPublish] = useState(false);
		return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                        <MoreVertical size="15px" className="text-secondary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Item onClick={() => setPublish(true)}>
                            {"Un-Publish"}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <InfoModal 
                    show={publish}
                    handleClose={() => setPublish(false)}
                    handleConfirm={handlePublish}
                    loading={publishLoading}
                    infoText={"Are you sure you want to Un-publish your course? \n Un-publishing your course means you course will be offline, and will not be accessible by users."}
                    infoTitle={"Un-Publishing Course!"}
                />
            </>
		);
	};


    export const ViewUserModal = ({show, handleClose, data}) => {

        return (
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-4">
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Full Name</span>
                        <span className="text-dark">
                            {data?.fullName}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Enrolled Courses</span>
                        <span className="text-dark">
                            {data?.totalCourseEnrolled}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Completed Courses</span>
                        <span className="text-dark">
                            {data?.totalCourseCompleted}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>In progress Courses</span>
                        <span className="text-dark">
                            {data?.totalCourseInprogress}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Completed Quizzes</span>
                        <span className="text-dark">
                            {data?.totalQuizCompleted}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Passed Quizzes</span>
                        <span className="text-dark">
                            {data?.totalQuizPassed}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Failed Quizzes</span>
                        <span className="text-dark">
                            {data?.totalQuizFailed}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Completed Assessment</span>
                        <span className="text-dark">
                            {data?.totalAssessmentCompleted}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Passed Assessment</span>
                        <span className="text-dark">
                            {data?.totalAssessmentPassed}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Failed Assessment</span>
                        <span className="text-dark">
                            {data?.totalAssessmentFailed}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-2 px-8">
                        <span>Completed Lesson</span>
                        <span className="text-dark">
                            {data?.totalLessonCompleted}
                        </span>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    export const CategoryMenu = ({ value, editResponse, postEditedCategory, handleDeleteConfirmation, confirmLoading, editCategoryLoading, editCategoryError }) => {
        const [show, setShow] = useState(false);
        const [remove, setRemove] = useState(false);

        const handleClose = () => {
            setShow(false);
        }

        useEffect(() => {
            if(editResponse && editResponse?.status == 'Success') {
                handleClose();
            }
        }, [editResponse])
        const handleShow = () => setShow(true);

        return (
            <div className='d-md-flex align-items-center justify-content-around'>
                <AileadButton type="button" text="Edit" onClick={handleShow} />
                <AileadButton type="button" variant='danger' text="Delete" onClick={() => setRemove(true)} />

                <AddNewCategoryPopup
				  handleClose={handleClose} 
				  show={show}
                  initialValue={value}
				  handleSubmit={postEditedCategory}
				  loading={editCategoryLoading}
				  error={editCategoryError}
				/>

                <DeleteConfirmationModal 
                    show={remove}
                    handleClose={() => setRemove(false)}
                    handleConfirm={handleDeleteConfirmation}
                    loading={confirmLoading}
                    item={value ? value.title: ""}
                />
            </div>
        );
    }

    export const DeleteConfirmationModal = ({
        show,
        handleClose,
        handleConfirm,
        item,
        loading
      }) => {

        const handleConfirmation = () => {
            handleConfirm();
            handleClose();
        }
        return (
          <Fragment>
            <Modal show={show} onHide={handleClose} size="sm">
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete <strong>{item ? item : ""}</strong>?
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-start border-0 pt-0">
                <AileadButton
                  text="Delete"
                  loading={loading}
                  variant="danger"
                  type="button"
                  onClick={handleConfirmation}
                  disabled={loading}
                />
                <AileadButton
                  text="Cancel"
                  variant="outline-secondary"
                  onClick={handleClose}
                  type="button"
                />
              </Modal.Footer>
            </Modal>
          </Fragment>
        );
    };


    export const InfoModal = ({
        show,
        handleClose,
        handleConfirm,
        loading,
        infoText,
        infoTitle
      }) => {

        const handleConfirmation = () => {
            handleConfirm();
            handleClose();
        }
        return (
          <Fragment>
            <Modal show={show} onHide={handleClose} size="sm">
              <Modal.Header closeButton>
                <Modal.Title>{infoTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {infoText}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-start border-0 pt-0">
                <AileadButton
                  text="Proceed"
                  loading={loading}
                  variant="primary"
                  onClick={handleConfirmation}
                  disabled={loading}
                  type="button"
                />
                <AileadButton
                  text="Cancel"
                  variant="outline-secondary"
                  onClick={handleClose}
                  type="button"
                />
              </Modal.Footer>
            </Modal>
          </Fragment>
        );
    };