import { Fragment, useState, useEffect } from 'react';
import { Form, Button, Modal, InputGroup, Spinner, ProgressBar } from 'react-bootstrap';

// Import custom components
import { useFileUpload } from 'hooks/useFileUpload';
import { usePostData } from 'hooks/usePostData';
import AileadButton from 'components/marketing/common/button/AileadButton';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import { Link } from 'react-router-dom';

export const AddLessonModal = ({ moduleIndex, addLesson, initialLesson, lessonIndex, formData }) => {
	const [show, setShow] = useState(false);
	const { response: validationResponse, loading: loadingValidation, error: validationErrors, postData } = usePostData('/course/validate/lesson');
	const { response: videoResponse, loading: loadingVideo, error: videoError, postData: postVideoData } = usePostData('/course/validate/video');
	const { serverResponse, uploadError, uploadProgress, isUploading, handleFileChange, handleUpload } = useFileUpload('/module/upload/doc');
	
	const defaultInit = {
		title: '',
		description: '',
		order: "",
		isLocked: false,
		content: {
			type: 'video',
			data: {},
		},
	};

	const [lesson, setLesson] = useState(defaultInit);
	const [fileUploadType, setFileUploadType] = useState("");

	useEffect(() => {
		if (initialLesson) {
			setLesson(initialLesson);
		}
	}, [initialLesson]);

	useEffect(() => {
	  if(serverResponse?.status === "Success") {

		const fileUrl = serverResponse?.data?.url;
		setLesson((prev) => ({
		  ...prev,
		  content: {
			...prev.content,
			data: {
			  ...prev.content.data,
			  url: fileUrl
			}
		  }
		}));

	  }
	}, [serverResponse])

	const handleClose = () => {
		if (!initialLesson) {
			setLesson(defaultInit);
		}
		setShow(false);
	};

	const handleShow = () => {
		if (!initialLesson) {
			setLesson(defaultInit);
		}
		setShow(true);
	};

	const handleAddLesson = () => {
		const maxOrder = formData.step4.modules[moduleIndex].lessons.reduce((max, lesson) => Math.max(max, lesson.order), 0);
		const newOrder = parseInt(maxOrder + 1);

		const newLesson = {
			...lesson,
			order: newOrder
		};

		const addNewLesson = initialLesson ? lesson : newLesson;

		addLesson(moduleIndex, addNewLesson, lessonIndex);
		handleClose();
	};

	const handleContentTypeChange = (e) => {
		setLesson({
			...lesson,
			content: {
				...lesson.content,
				type: e.target.value,
			},
		});
	};

	const handleContentInputChange = (field, value) => {
		setLesson((prevLesson) => ({
			...prevLesson,
			content: {
				...prevLesson.content,
				data: {
					...prevLesson.content.data,
					[field]: value,
				},
			},
		}));
	};

	const addLectureStep = () => {
		postData(lesson);
	};

	useEffect(() => {
		if (validationResponse !== null && validationResponse?.status === "Success") {
			handleAddLesson();
		}
	}, [validationResponse]);

	const [isVerified, setIsVerified] = useState(false);

	// Debounced input handling
	useEffect(() => {
	  // Only validate if there is a URL
	  if (!lesson.content.data.url) return;
	  
	// Set a timer to debounce
	const timer = setTimeout(() => {
		// Post the URL to the server for validation
		postVideoData({ url: lesson.content.data.url });
		}, 500); // 500ms delay for debouncing

	  // Clear timer if URL changes before timeout
	  return () => clearTimeout(timer);
	}, [lesson.content.data.url]);

	// Set verification status based on response
	useEffect(() => {
		if (videoResponse?.status === "Success") {
		  setLesson((prev) => ({
			...prev,
			content: {
			  ...prev.content,
			  data: {
				...prev.content.data,
				url: videoResponse?.data?.url
			  }
			}
		  }))
		  setIsVerified(true);
		} else {
		  setIsVerified(false);
		}
	}, [videoResponse]);

	return (
		<Fragment>
			{
				initialLesson ?
					<Button
						className="me-1 text-inherit"
						title="Edit"
						onClick={handleShow}
					>
						<i className="fe fe-edit fs-6"></i>
					</Button> :
					<Button
						variant="outline-primary"
						className="btn btn-outline-primary btn-sm mt-3"
						onClick={handleShow}
					>
						Add Lesson +
					</Button>
			}
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{initialLesson ? "Edit Lesson" : "Add New Lesson"}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group className="mb-3" controlId="formaddnewlecture">
						<Form.Label>Lesson Title <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<Form.Control
							type="text"
							placeholder="Add Lesson Title"
							className='mb-4'
							value={lesson.title}
							onChange={(e) => setLesson({ ...lesson, title: e.target.value })}
						/>
						{validationErrors?.title && (
							<Form.Text className="col-sm-6 text-danger mb-2 mt-2">
								{validationErrors?.title[0]}
							</Form.Text>
						)}

						<Form.Label>Lesson Description <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<RichTextEditor
							editorValue={lesson.description}
							onChange={(event, editor) => {
								const newData = editor.getData();
								setLesson({ ...lesson, description: newData })
							}}
						/>
						{validationErrors?.description && (
							<Form.Text className="col-sm-6 text-danger mb-2 mt-2">
								{validationErrors?.description[0]}
							</Form.Text>
						)}

						<Form.Label>Unlock Content</Form.Label>
						<Form.Check
							type="checkbox"
							checked={lesson.isLocked}
							className="form-switch mb-3"
							onChange={(e) => setLesson({ ...lesson, isLocked: !lesson.isLocked })}
						/>

						<Form.Label>Lesson Content Type <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<Form.Select
							className='mb-4'
							value={lesson.content.type}
							onChange={handleContentTypeChange}
						>
							<option value="video">Video</option>
							<option value="text">Text</option>
							<option value="file">File</option>
						</Form.Select>

						{/* Conditional content fields */}
						{lesson.content.type === 'video' && (
							<>
							<Form.Label>
							  Video URL <Form.Text className='text-danger'>*</Form.Text>
							</Form.Label>
							
							<InputGroup hasValidation className="mb-4">
							  <Form.Control
								type="text"
								placeholder="Enter Video URL"
								value={lesson.content.data.url || ''}
								onChange={(e) => handleContentInputChange('url', e.target.value)}
								isInvalid={!!validationErrors?.url || videoError}
								className='video-url-input'
							  />
							  
							  {/* Loading Spinner */}
							  {loadingVideo && (
								<InputGroup.Text>
								  <Spinner animation="border" size="sm" />
								</InputGroup.Text>
							  )}
							  
							  {/* Checkmark or Error Icon */}
							  {!loadingVideo && isVerified && (
								<InputGroup.Text className="text-success">✔️</InputGroup.Text>
							  )}
							  {!loadingVideo && videoError && (
								<InputGroup.Text className="text-danger">❌</InputGroup.Text>
							  )}
							</InputGroup>
					  
							{/* Error Message */}
							{(validationErrors?.url || videoError) && (
							  <Form.Text className="text-danger mb-4">
								{validationErrors?.url ? validationErrors.url[0] : 'Invalid video URL'}
							  </Form.Text>
							)}
						  </>
						)}


						{lesson.content.type === 'text' && (
							<>
								<Form.Label>Text Content</Form.Label>
								<Form.Control
									as="textarea"
									rows={4}
									placeholder="Add Text Content"
									value={lesson.content.data.textContent || ''}
									onChange={(e) => handleContentInputChange('textContent', e.target.value)}
									className='mb-4'
								/>
								{validationErrors?.content && (
									<Form.Text className="text-danger mb-2 mt-3">
										{validationErrors.content[0]}
									</Form.Text>
								)}
							</>
						)}

						{lesson.content.type === 'file' && (
							<>
								<Form.Label>File Options</Form.Label>
								<Form.Check
									type="radio"
									name="fileUploadType"
									label="Upload File"
									checked={fileUploadType === "upload"}
									onChange={() => setFileUploadType("upload")}
									className="mb-2"
								/>
								{fileUploadType === "upload" && (
									<>
										<Form.Control
											type="file"
											onChange={handleFileChange}
											className="mb-3"
										/>
										<Link className='mb-2' target='_blank' to={lesson.content.data.url}>{lesson.content.data.url}</Link>
										<AileadButton
											type="button"
											variant="primary"
											onClick={handleUpload}
											text="Upload File"
											disabled={isUploading}
										/>
										{isUploading && (
											<ProgressBar
												striped
												variant="success"
												now={uploadProgress}
												label={`${uploadProgress}%`}
												className="mt-3"
												style={{ height: '16px' }}
											/>
										)}
										{uploadError?.errors && (
											<Form.Text className="text-danger mt-2">
												{uploadError?.errors?.url[0]}
											</Form.Text>
										)}
									</>
								)}
								<Form.Check
									type="radio"
									name="fileUploadType"
									label="Enter File URL"
									checked={fileUploadType === "url"}
									onChange={() => setFileUploadType("url")}
									className="mt-2"
								/>
								{fileUploadType === "url" && (
									
								  <Form.Control
									type="text"
									placeholder="Add File URL"
									value={lesson.content.data.url || ''}
									onChange={(e) => handleContentInputChange('url', e.target.value)}
									className="mt-2"
								  />
									
								)}
							</>
						)}
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>Close</Button>
					<AileadButton
						type="button"
						variant="primary"
						onClick={addLectureStep}
						text={loadingValidation ? "Validating Lesson..." : "Save Lesson"}
						loading={loadingValidation}
					/>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};
