import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import NewPagination from 'components/elements/advance-table/NewPagination';
import AileadButton from 'components/marketing/common/button/AileadButton';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import { DisplayFileIcons } from 'components/elements/files/DisplayFileIcons';
import { DisplayFileList } from 'components/elements/files/DisplayFileList';

const DiscussionTable = ({
    discussionData,
    handlePageChange,
    currentPage,
    lastPage,
    refreshData,
    loading
}) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [editingRow, setEditingRow] = useState(null); // Track the currently editing row
    const [editValues, setEditValues] = useState({}); // Store editable values for each child

    // Toggle the expanded state for a given discussion ID
    const toggleRow = (discussionId) => {
        setExpandedRows((prev) =>
            prev.includes(discussionId) ? prev.filter((id) => id !== discussionId) : [...prev, discussionId]
        );
    };

    // Start editing a child row
    const startEditing = (childId, initialContent, initialAttachments) => {
        setEditingRow(childId);
        setEditValues({ ...editValues, [childId]: { content: initialContent, attachments: initialAttachments || [] } });
    };

    // Save the edited content for a specific child
    const saveEdit = (childId) => {
        console.log(`Saved content for child ID ${childId}:`, editValues[childId]);
        setEditingRow(null); // Close the editing mode
    };

    // Handle content changes for a specific child
    const handleContentChange = (childId, value) => {
        setEditValues({ ...editValues, [childId]: { ...editValues[childId], content: value } });
    };

    // Handle file changes for a specific child
    const handleFileChange = (childId, e) => {
        const files = Array.from(e.target.files);
        setEditValues((prev) => ({
            ...prev,
            [childId]: { ...prev[childId], attachments: [...(prev[childId].attachments || []), ...files] }
        }));
    };

    // Remove a file for a specific child
    const handleFileRemove = (childId, index) => {
        setEditValues((prev) => ({
            ...prev,
            [childId]: {
                ...prev[childId],
                attachments: prev[childId].attachments.filter((_, i) => i !== index)
            }
        }));
    };

    return (
        <>
            <div className="table-responsive">
                <Table hover className="table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Cohort</th>
                            <th>Course</th>
                            <th>Module</th>
                            <th>Week</th>
                            <th>Replies</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {discussionData?.length > 0 ? (
                            discussionData?.map((discussion, index) => (
                                <React.Fragment key={discussion.id}>
                                    {/* Parent Row */}
                                    <tr onClick={() => toggleRow(discussion.id)} style={{ cursor: 'pointer' }}>
                                        <td>{index + 1}</td>
                                        <td>{discussion.title}</td>
                                        <td>{discussion.courseTitle}</td>
                                        <td>{discussion.moduleTitle}</td>
                                        <td>{discussion.week}</td>
                                        <td>{discussion.replies}</td>
                                        <td>{discussion.status}</td>
                                        <td>
                                            {expandedRows.includes(discussion.id) ? <i className="fe fe-chevron-up fw-semi-bold"></i> : <i className="fe fe-chevron-down fw-semi-bold"></i>}
                                        </td>
                                    </tr>

                                    {/* Expandable Child Row */}
                                    {expandedRows.includes(discussion.id) && (
                                        <tr>
                                            <td colSpan="8" style={{ backgroundColor: '#f9f9f9' }}>
                                                <div className="p-3">
                                                    <h5>Discussion Details</h5>
                                                    {/* Display children in a row format */}
                                                    {discussion?.children?.length > 0 ? (
                                                        <Table hover className="mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>Instructor</th>
                                                                    <th>Content</th>
                                                                    <th>Attachments</th>
                                                                    <th>Replies</th>
                                                                    <th>Date</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {discussion.children.map((child) => (
                                                                    <tr key={child.id}>
                                                                        <td>{child.instructor}</td>
                                                                        <td>
                                                                            {/* Toggle between view and edit mode */}
                                                                            {editingRow === child.id ? (
                                                                                <RichTextEditor
                                                                                    editorValue={editValues[child.id]?.content || ''}
                                                                                    onChange={(event, editor) => {
                                                                                        handleContentChange(child.id, editor.getData());
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <span>{child.content}</span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {editingRow === child.id ? (
                                                                                <>
                                                                                    <DisplayFileList
                                                                                        handleFileRemove={(index) => handleFileRemove(child.id, index)}
                                                                                        attachments={editValues[child.id]?.attachments || []}
                                                                                    />
                                                                                    <DisplayFileIcons
                                                                                        handleFileChange={(e) => handleFileChange(child.id, e)}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <DisplayFileList attachments={child.attachments || []} />
                                                                            )}
                                                                        </td>
                                                                        <td>{child.replies}</td>
                                                                        <td>{child.date}</td>
                                                                        <td>
                                                                            {editingRow === child.id ? (
                                                                                <>
                                                                                    {/* Save Button */}
                                                                                    <Button
                                                                                        variant="primary"
                                                                                        onClick={() => saveEdit(child.id)}
                                                                                    >
                                                                                        Save
                                                                                    </Button>
                                                                                    <Button
                                                                                        variant="secondary"
                                                                                        className="ms-2"
                                                                                        onClick={() => setEditingRow(null)}
                                                                                    >
                                                                                        Cancel
                                                                                    </Button>
                                                                                </>
                                                                            ) : (
                                                                                <Button
                                                                                    variant="info"
                                                                                    onClick={() =>
                                                                                        startEditing(
                                                                                            child.id,
                                                                                            child.content,
                                                                                            child.attachments
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Edit
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    ) : (
                                                        <p>No child discussions available for this entry.</p>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        ) : loading ? (
                            <tr>
                                <td colSpan="8" className="text-center">Fetching Data Please wait...</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">No Discussion Scheduler available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <NewPagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
        </>
    );
};

export default DiscussionTable;