// import node module libraries
import React, { Fragment, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';

// import custom components
import { Avatar } from 'components/elements/bootstrap/Avatar';
import { useFetchData } from 'hooks/useFetchData';
import { getStatusColor } from 'helper/utils';
import socket from 'helper/socket';

const Message = () => {
	const location = useLocation();
	const { threadId, groupId } = location.state || {}; // Retrieve the state data
	const { data: messages, refreshData } = useFetchData(`/message/replies/${threadId}?groupId=${groupId}&page=1`);
	const BASE_URL = process.env.REACT_APP_ASSET_BASE_URL;

	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
	  messagesEndRef.current.scrollIntoView({
		behavior: 'smooth',
		block: 'end',
		inline: 'nearest'
	  });
	};
  
	useEffect(scrollToBottom, [messages]);

	useEffect(() => {
		const connectWebSocket = () => {
			// Listen for the send Message to group event
			socket.on('sendMessageToUser', (groupId, message) => {
				refreshData();
			});
		};

		connectWebSocket(); // 

		return () => {
			socket.off('sendMessageToUser');
		};
	}, [refreshData]);

	const CardHeading = (item) => {
		const { avatar, fullName } = item;
		return (
			<div className="d-flex align-items-center">
				<Avatar
					size="md"
					src={`${BASE_URL}/${avatar}`}
					type={'image'}
					name={fullName}
					className="rounded-circle"
				/>
				<div className="ms-3">
					<h4 className="mb-0">
						<Link to="#" className="text-inherit">
							{fullName}
						</Link>
					</h4>
					<span className="text-muted fs-6"></span>
				</div>
			</div>
		);
	};

	return (
		<Fragment>
			<Card className="h-100 mb-4">
				<Card.Body>
					{/* heading*/}
					{messages?.data && CardHeading(messages?.data?.instructor) }
					{/* Wrap all image attachments in a single row container */}
					<div className='mb-4 mt-4' style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
					{messages?.data?.attachments[0]?.attachments?.map((attachment, i) => {
						if (attachment.mime_type && attachment.mime_type.startsWith("image/")) {
							return (
								<div key={attachment.file_path} style={{ maxWidth: "48%", flex: "1 1 auto" }}>
								<Card.Img
									variant="top"
									src={`${BASE_URL}/${attachment.file_path}`} // Use the attachment's URL
									className="img-fluid rounded-top"
									style={{ width: "100%" }} // Control the image width inside the flex item
								/>
								</div>
							);
						}
						return null; // If it's not an image, return null
					})}
					</div>
					
					<div className="mt-3 mb-4">
						<p className="mb-0" dangerouslySetInnerHTML={{__html: messages?.data?.content}}></p>
					</div>
					
					{/* progress */}
					<div className="d-flex justify-content-between align-items-center mb-5">
						{/* text */}
						<div>
							<span
								className={`badge bg-light-${getStatusColor(
									messages?.data?.status
								)} text-dark-${getStatusColor(messages?.data?.status)}`}
							>
								{messages?.data?.status}
							</span>
						</div>
					</div>

					<div>
						{/* Display reactions here */}
					</div>
				</Card.Body>
			</Card>
			<div style={{ width: "100%", maxWidth: "90%",  margin: "0 auto" }}>
				{messages?.data?.replies?.map((message, i) => <Card className="h-100 mb-4">
					<Card.Body>
						{/* heading*/}
						{message && CardHeading(message?.user)}

						<div className="mt-3 mb-4">
							<p className="mb-0" dangerouslySetInnerHTML={{__html: message?.content}}></p>
						</div>
						
						{/* progress */}
						<div className="d-flex justify-content-between align-items-center mb-5">
							{/* text */}
							<div>
								<span
									className={`badge bg-light-${getStatusColor(
										message?.status
									)} text-dark-${getStatusColor(message?.status)}`}
								>
									{message?.status}
								</span>
							</div>
						</div>
						<div>
						{/* Display reactions here */}
						</div>
					</Card.Body>
				</Card>)}
				{/* Invisible div to scroll into view */}
				<div ref={messagesEndRef} />
			</div>
		</Fragment>
	);
};
export default Message;
