import React from 'react';
import ReactPlayer from 'react-player';
import { usePostData } from 'hooks/usePostData';

const ServeContent = ({value}) => {

  const TextViewer = ({ content }) => {
    return (
      <p dangerouslySetInnerHTML={{__html:content}}></p>
    );
  };

  const VideoPlayer = ({ url,  lessonId, courseId}) => {
    const { postData } = usePostData("/user/lesson/status");
    const handleVideoEnd = () => {
      postData({ lessonId: lessonId, courseId: courseId });
    };
  
    return (
      <ReactPlayer 
        url={`${url}`}
        controls
        className="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100"  
        onEnded={handleVideoEnd}
      />
    );
  };

  const ContentType = ({ data }) => {

    switch(data?.type) {
      case "video":
        return (
          <VideoPlayer url={data?.url} lessonId={data?.lessonId} courseId={data?.courseId}  /> 
        )

      case "text":
        return (
          <TextViewer description={data?.description} />
        )
		}
    
  }

  return (
    <ContentType data={value} />
  );
};

export default ServeContent;
