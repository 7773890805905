import React from 'react';
import TableWrapper from '../../common/table/TableWrapper';
import QuizTable from './QuizTable'; // Your existing courseTable component
import { useFetchData } from 'hooks/useFetchData';

const QuizList = () => {
  const { data } = useFetchData('/trainer/quizzes')
  const tabs = [
    { eventKey: 'graded', label: 'Graded', component: <QuizTable quizzes={data?.data?.graded?.data || []} /> },
    { eventKey: 'un-graded', label: 'Un-Graded', component: <QuizTable quizzes={data?.data?.unGraded?.data || []} /> },
    { eventKey: 'assessment', label: 'Assessment', component: <QuizTable quizzes={data?.data?.assessment?.data || []} /> },
  ];

  return (
    <TableWrapper
      title="All Quizzes"
      breadcrumbs={[
        { label: 'Home', link: '#' },
        { label: 'All', active: true },
      ]}
      buttonLink="/dashboard/course/add-quiz"
      buttonText="Add New Quizzes"
      tabs={tabs}
      defaultTabKey="graded"
    />
  );
};

export default QuizList;
