import React, { useEffect, useState } from 'react';
import { Modal, Form, Image } from 'react-bootstrap';
import AileadButton from 'components/marketing/common/button/AileadButton';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import { useFetchData } from 'hooks/useFetchData';
import { notifySuccess } from 'helper/utils';

const WeeklyThread = ({ showModal, handleClose, submit, loading, error, response, refresh}) => {
  // Fetch group, course, and module data
  const { data } = useFetchData('/message/courses');

  // State to manage dropdown selections
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [week, setWeek] = useState('');
  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState([]); // Initialize attachments as an array

  // Reset course selection when group changes
  useEffect(() => {
    setSelectedCourse('');
    setSelectedModule(''); // Also reset module selection
  }, [selectedGroup]);

  // Reset module selection when course changes
  useEffect(() => {
    setSelectedModule('');
  }, [selectedCourse]);

  const handleGroupChanged = (e) => {
    const [weekNumber, groupId] = e.target.value.split('-');
    setSelectedGroup(groupId);
    setWeek(weekNumber);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    submit(
      {
        week,
        content,
        attachments,
        groupId: selectedGroup,
        courseId: selectedCourse,
        moduleId: selectedModule,
      },
      true
    );
  };

  useEffect(() => {
    if(response?.status === "Success") {
      notifySuccess("Weekly Thread Scheduled, successfully!")
      refresh();
      handleClose();
    }
  },[response]);

  // Handle file change for attachments
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  };

  // Handle file removal
  const handleFileRemove = (index) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create New Thread</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Group Dropdown */}
          <Form.Group className="mb-3">
            <Form.Label>Select Group</Form.Label>
            <Form.Control as="select" value={selectedGroup ? `${week}-${selectedGroup}` : ''} onChange={handleGroupChanged} required>
              <option value="">Choose Group...</option>
              {data?.data?.map((group) => (
                <option key={group.id} value={`${group.week}-${group.id}`}>
                  {group.title}
                </option>
              ))}
            </Form.Control>
            {error?.groupId && <small className="text-danger mt-4">{error?.groupId[0]}</small>}
          </Form.Group>

          {/* Course Dropdown (conditional based on group selection) */}
          {selectedGroup && (
            <Form.Group className="mb-3">
              <Form.Label>Select Course</Form.Label>
              <Form.Control as="select" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)} required>
                <option value="">Choose Course...</option>
                {data?.data
                  .find((group) => group.id === parseInt(selectedGroup))
                  ?.courses.map((course) => (
                    <option key={course.id} value={course.id}>
                      {course.title}
                    </option>
                  ))}
              </Form.Control>
              {error?.courseId && <small className="text-danger mt-4">{error?.courseId[0]}</small>}
            </Form.Group>
          )}

          {/* Module Dropdown (conditional based on course selection) */}
          {selectedCourse && (
            <Form.Group className="mb-3">
              <Form.Label>Select Module</Form.Label>
              <Form.Control as="select" value={selectedModule} onChange={(e) => setSelectedModule(e.target.value)} required>
                <option value="">Choose Module...</option>
                {data?.data
                  .find((group) => group.id === parseInt(selectedGroup))
                  ?.courses.find((course) => course.id === parseInt(selectedCourse))
                  ?.modules.map((module) => (
                    <option key={module.id} value={module.id}>
                      {module.title}
                    </option>
                  ))}
              </Form.Control>
              {error?.moduleId && <small className="text-danger mt-4">{error?.moduleId[0]}</small>}
            </Form.Group>
          )}

          {/* Week */}
          <Form.Group className="mb-3">
            <Form.Label>Week</Form.Label>
            <Form.Control value={week} readOnly required />
            {error?.week && <small className="text-danger mt-4">{error?.week[0]}</small>}
          </Form.Group>

          {/* Thread Content */}
          <Form.Group className="mb-3">
            <Form.Label>Thread Content</Form.Label>
            <RichTextEditor
              editorValue={content}
              onChange={(event, editor) => {
                const newData = editor.getData();
                setContent(newData);
              }}
            />
            {error?.body && <small className="text-danger mt-4">{error?.body[0]}</small>}
          </Form.Group>

          {/* File Upload Section */}
          <Form.Group className="mb-3">
            <Form.Label>Attach Files/Images</Form.Label>
            <Form.Control type="file" multiple onChange={handleFileChange} />
          </Form.Group>

          {/* Display Selected Files/Images */}
          {attachments.length > 0 && (
            <div className="mb-3">
              <Form.Label>Selected Files/Images</Form.Label>
              <div className="d-flex flex-wrap">
                {attachments.map((file, index) => (
                  <div key={index} className="me-3 mb-3" style={{ position: 'relative' }}>
                    {file.type.startsWith('image/') ? (
                      <Image src={URL.createObjectURL(file)} alt={file.name} thumbnail style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                    ) : (
                      <div className="file-preview" style={{ width: '100px', height: '100px', border: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>{file.name}</span>
                      </div>
                    )}
                    {/* Remove File Button */}
                    <button type="button" className="btn-close" style={{ position: 'absolute', top: '0', right: '0' }} onClick={() => handleFileRemove(index)}></button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <AileadButton variant="primary" type="button" loading={loading} text="Create Thread" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};

export default WeeklyThread;
