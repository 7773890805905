// Import node module libraries
import {useEffect} from 'react';
import { Table } from 'react-bootstrap';

// Import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { InstructorData, CourseInitialColumn, PublishedMenu, CourseStatus } from '../../business/all-courses/common/Components';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';

const CoursesTable = ({ 
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
    refreshData,
    loading
}) => {
    const { response: publishResponse, loading: publishLoading, error: publishError, postData: publishPostData } = usePostData('/course/status');

    useEffect(() => {
		if(publishResponse && publishResponse?.status === "Success") {
			refreshData();
			notifySuccess(`Course has been move to trash Successfully!`);
		}
	}, [publishResponse])

    return (
        <>
            <div className="table-responsive">
                <Table hover className="table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Course</th>
                            <th>Category</th>
                            <th>Instructor</th>
                            <th>Level</th>
                            <th>Enrollled</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses_data?.data?.length > 0 ? (
                            courses_data?.data?.map((course, index) => (
                                <tr key={course.id}>
                                    <td className='text-dark'>{index + 1}</td>
                                    <td className='text-dark'><CourseInitialColumn course={course} /></td>
                                    <td className='text-dark'>{course.category?.title || 'N/A'}</td>
                                    <td className='text-dark'><InstructorData row={course} /></td>
                                    <td className='text-dark'>{course.level}</td>
                                    <td className='text-dark'>{course.studentsEnrolled}</td>
                                    <td className='text-dark'>{course.publishedDate}</td>
                                    <td className='text-dark'><CourseStatus value={course} /></td>
                                    <td className='text-dark'>
                                        <PublishedMenu
                                          publishLoading={publishLoading} 
                                          handlePublish={() => publishPostData({courseId: course.id})}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : loading ? (
                            <tr>
                                <td colSpan="8" className="text-center">Fetching Data Please wait .....</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">No Published courses available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            
            <NewPagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
            
        </>
    );
};

export default CoursesTable;
