// import node module libraries
import React, { useState } from 'react';
import { Col, Row, Container, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useFetchData } from 'hooks/useFetchData';
import GKAccordionDefault from 'components/marketing/common/accordions/GKAccordionDefault';
import ServeContent from './ServeContent';

export const CourseVideoReview = ({ slug: propSlug }) => {
	const { slug: paramSlug } = useParams();
	const slug = propSlug || paramSlug;
	const [url, setUrl] = useState(null);
	const { data } = useFetchData(`/course/${slug}`);

	return (
		<main>
			<section className="mt-6 course-container">
				<Container fluid className='bg-light'>
					<Row>
						<Col sm={12} md={12} lg={12}>
							{/*  Tab content  */}
							<div className="content">
								<div
									className="embed-responsive position-relative w-100 d-block overflow-hidden p-0 mb-2 mt-2"
									style={{ height: '600px' }}
								>
									<ServeContent value={url} />
								</div>
							</div>
						</Col>
					</Row>
				</Container>  
			</section>
			{/*  Card */}
			<section className="card course-sidebar " id="courseAccordion">
				<SimpleBar style={{ maxHeight: '93vh' }}>
					<Card>
						<Card.Header>
								<h4 className="mb-0">Table of Content</h4>
						</Card.Header>
						{/* Course Index Accordion */}

						<GKAccordionDefault 
							accordionItems={data?.data} 
							setValue={setUrl} 
						/>
					</Card>
				</SimpleBar>
			</section>
		</main>
	);
};

export default CourseVideoReview;
