import React from 'react';
import ReusableTable from '../../common/table/ReusableTable';

const PayoutTable = ({ payouts }) => {
  const columns = [
    { header: '#', accessor: '' },
    { header: 'Amount', accessor: 'amount' },
    { header: 'Request Date', accessor: 'requestDate' },
    { header: 'Received Date', accessor: 'receiveDate' },
    { header: 'Status', accessor: 'status' },
  ];

  const actions = [
    { label: 'View/Edit', icon: null }
  ];

  return <ReusableTable data={payouts} columns={columns} actions={actions} tableName="Payout" />;
};

export default PayoutTable;
