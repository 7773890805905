import React from 'react';
import ReusableTable from '../../common/table/ReusableTable';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';

const QuizTable = ({ quizzes }) => {
  const navigate = useNavigate();
  
  const columns = [
    { header: '#', accessor: '' },
    { header: 'Quiz', accessor: 'quiz', render: (value, row) => (
      <div className="d-lg-flex align-items-center">
        <div>
          <Image
            src={row?.course?.image || ""}
            alt=""
            className="img-4by3-lg rounded"
          />
        </div>
          <div className="ms-lg-3 mt-2 mt-lg-0">
            <h4 className="mb-1 text-primary-hover">{row?.title}</h4>
            <span className="text-inherit">
              Course: {row?.course?.title || ""}
            </span>
          </div>
      </div>
    )},
    
    { header: 'Type', accessor: 'type' },
    { header: 'Module', accessor: 'module', render: (value, row) => (<div>{ row?.module?.title }</div>) },
    { header: 'Lesson', accessor: 'lesson', render: (value, row) => (<div>{ row?.lesson?.title }</div>) },
    { header: 'Questions', accessor: 'question', render: (value, row) => (<div>{ row?.questions?.length }</div>) },
    { header: 'Attempts', accessor: 'maxAttempt' },
  ];

  const actions = [
    { label: 'View/Edit', icon: null, onClick: (row) => navigate(`dashboard/course/edit-quiz/${row?.id}`) }
  ];

  return <ReusableTable data={quizzes} columns={columns} actions={actions} tableName="Quiz" />;
};

export default QuizTable;
