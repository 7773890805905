// import node module libraries
import AileadButton from 'components/marketing/common/button/AileadButton';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Image } from 'react-bootstrap';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';

const NewThreadModal = ({ showModal, handleClose, submit, loading, groupId, error }) => {
  // Form state
  const [week, setWeek] = useState('');
  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState([]); // Initialize attachments as an array

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form data, including attachments
    submit({ week, content, attachments, groupId }, true);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  };

  // Handle file/image removal
  const handleFileRemove = (index) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  // Week options (1 to 52)
  const weeks = Array.from({ length: 52 }, (_, i) => `Week ${i + 1}`);

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create New Thread</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Week Dropdown */}
          <Form.Group className="mb-3">
            <Form.Label>Select Week</Form.Label>
            <Form.Control
              as="select"
              value={week}
              onChange={(e) => setWeek(e.target.value)}
              required
            >
              <option value="">Choose Week...</option>
              {weeks.map((week, index) => (
                <option key={index} value={index + 1}>
                  {week}
                </option>
              ))}
            </Form.Control>
            {error?.week && <small className='text-danger mt-4'>{error?.week[0]}</small>}
          </Form.Group>

          {/* Thread Content */}
          <Form.Group className="mb-3">
            <Form.Label>Thread Content</Form.Label>
            <RichTextEditor
              editorValue={content}
              onChange={(event, editor) => {
                const newData = editor.getData();
                setContent(newData);
              }}
            />
            {error?.body && <small className='text-danger mt-4'>{error?.body[0]}</small>}

          </Form.Group>

          {/* Display Selected Files/Images */}
          {attachments.length > 0 && (
            <div className="mb-3">
              <Form.Label>Selected Files/Images</Form.Label>
              <div className="d-flex flex-wrap">
                {attachments.map((file, index) => (
                  <div key={index} className="me-3 mb-3" style={{ position: 'relative' }}>
                    {file.type.startsWith('image/') ? (
                      <Image
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        thumbnail
                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                      />
                    ) : (
                      <div className="file-preview" style={{ width: '100px', height: '100px', border: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>{file.name}</span>
                      </div>
                    )}
                    {/* Remove File Button */}
                    <button
                      type="button"
                      className="btn-close"
                      style={{ position: 'absolute', top: '0', right: '0' }}
                      onClick={() => handleFileRemove(index)}
                    ></button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="d-flex align-items-center">
          <AileadButton
            variant="primary"
            type="button"
            loading={loading}
            text="Create Thread"
            onClick={handleSubmit}
          />

          {/* Hidden File Input for Attachments */}
          <Form.Group className="ms-4" controlId="attachment-upload" style={{ display: 'none' }}>
            <Form.Control
              type="file"
              multiple
              onChange={handleFileChange}
              id="attachment-file-input"
            />
          </Form.Group>

          {/* Hidden File Input for Images */}
          <Form.Group className="ms-4" controlId="image-upload" style={{ display: 'none' }}>
            <Form.Control
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              id="image-file-input"
            />
          </Form.Group>

          {/* Attachment Icon */}
          <i
            className="fe fe-paperclip text-muted ms-3 cursor-pointer"
            onClick={() => document.getElementById('attachment-file-input').click()}
            style={{ fontSize: '1.5rem' }}
          ></i>

          {/* Image Icon */}
          <i
            className="fe fe-image text-muted ms-3 cursor-pointer"
            onClick={() => document.getElementById('image-file-input').click()}
            style={{ fontSize: '1.5rem' }}
          ></i>
        </div>
        {error?.attachments && <small className='text-danger mt-4'>{error?.attachments[0]}</small>}
      </Modal.Footer>
    </Modal>
  );
};

export default NewThreadModal;
