// import node module libraries
import { useEffect, useState } from 'react';
import { Table, Modal, Form, Button } from 'react-bootstrap';

// import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { InstructorData, CourseInitialColumn, EditableMenu, CourseStatus } from '../../business/all-courses/common/Components';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';
import CourseVideoReview from 'components/shared/CourseVideoReview';

const UnpublishedCoursesTable = ({
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
	refreshData,
	loading
}) => {
	const { response: deleteResponse, loading: deleteLoading, postData } = usePostData('/course/remove');
	const { response: publishResponse, loading: publishLoading, postData: publishPostData } = usePostData('/course/status');
	const { response: rejectResponse, loading: rejectLoading, postData: rejectPostData } = usePostData('/course/reject');
	const [selectedCourse, setSelectedCourse] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [rejectionMessage, setRejectionMessage] = useState("");
	
	// Handles opening the course modal
	const handleCourseClick = (course) => {
		setSelectedCourse(course);
		setShowModal(true);
	};
	
	// Close the modal
	const handleCloseModal = () => setShowModal(false);

	const handleReject = (course) => {
	  rejectPostData({ courseId: course.id })
	}

	const handleApproveAndPublish = (course) => {
	  publishPostData({ courseId: course.id })
	}

		
	useEffect(() => {
		if((deleteResponse && deleteResponse?.status === "Success") ||
		   (publishResponse && publishResponse?.status === "Success")) {
			refreshData();
			notifySuccess(`Action Completed Successfully!`);
		}

	}, [deleteResponse, publishResponse])

	useEffect(() => {
	  if((rejectResponse?.status === "Success")) {
		refreshData();
		notifySuccess(`Course Disapproved Successfully!`);
	  }
	}, [rejectResponse])

	return (
		<>
			<div className="table-responsive">
				<Table hover className="table-centered">
					<thead className="table-light">
						<tr>
							<th>#</th>
							<th>Course</th>
							<th>Category</th>
							<th>Instructor</th>
							<th>Level</th>
							<th>Created Date</th>
							<th>Status</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{ courses_data?.data && courses_data?.data.length > 0 ? (
							courses_data?.data && courses_data?.data.map((course, index) => (
								<tr key={course.id}>
									<td>{index + 1}</td>
									<td><CourseInitialColumn course={course} /></td>
									<td>{course.category?.title || 'N/A'}</td>
									<td><InstructorData row={course} /></td>
									<td>{course.level}</td>
									<td>{course.publishedDate}</td>
									<td><CourseStatus value={course} /></td>
									<td>
										<EditableMenu 
                                          value={course} 
                                          removeCourseLoading={deleteLoading} 
                                          handleDeleteCourse={() => postData({courseId: course.id})}
										  handlePublish={() => publishPostData({courseId: course.id})}
										  publishLoading={publishLoading}
                                        />
										{course?.instructor?.id !== null && (<AileadButton
										  text="Review"
										  onClick={() => handleCourseClick(course)}
										/>)}
									</td>
								</tr>
							))
							) : loading ? (
								<tr>
									<td colSpan="8" className="text-center">Fetching Data Please wait .....</td>
								</tr>
							): (
								<tr>
									<td colSpan="8" className="text-center">No Unpublished courses available.</td>
								</tr>
							)
						}
					</tbody>
				</Table>
			</div>

			<NewPagination
				currentPage={currentPage}
				lastPage={lastPage}
				onPageChange={handlePageChange}
			/>

			{/* Course Modal */}
			{selectedCourse && (
				<Modal show={showModal} onHide={handleCloseModal} fullscreen>
					<Modal.Header closeButton>
						<Modal.Title>Reviewing {selectedCourse?.title} Course</Modal.Title>
					</Modal.Header>
					<Modal.Body className="d-flex">
						
						{/* Main Content: Course Review Area */}
						<div className="flex-grow-1 p-3" style={{overflowY: "auto"}}>
							<CourseVideoReview slug={selectedCourse?.slug} />
						</div>
						
						{/* Sidebar: Admin Actions */}
						<div className="bg-light p-4" style={{ width: '300px', borderLeft: "1px solid #ddd", boxShadow: "-2px 0 5px rgba(0,0,0,0.1)" }}>
							<h5>Admin Actions</h5>
							<Form>
								<Form.Group controlId="rejectionMessage" className="mb-3">
									<Form.Label>Rejection Message</Form.Label>
									<Form.Control
										as="textarea"
										rows={10}
										placeholder="Enter reason for rejection (if any)"
										value={rejectionMessage}
										onChange={(e) => setRejectionMessage(e.target.value)}
									/>
								</Form.Group>

								{/* Action Buttons */}
								<div className="d-flex flex-column gap-2">
									<AileadButton type="button" text="Reject" loading={rejectLoading} variant="danger" onClick={() => handleReject(selectedCourse)} />
									<AileadButton type="button" text="Approve & Publish" loading={publishLoading} variant="primary" onClick={() => handleApproveAndPublish(selectedCourse)} />
								</div>
							</Form>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default UnpublishedCoursesTable;
