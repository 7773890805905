import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tab, Card, Nav, Breadcrumb, FormControl } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import GradedQuizTable from './GradedQuizTable';
import UnGradedQuizTable from './UnGradedQuizTable';
import AssessmentQuizTable from './AssessmentQuizTable';

const AllQuizes = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useSelector(state => state.auth);
  const authToken = user && user?.token;

  const [searchQuery, setSearchQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const fetchCourses = async ({ queryKey }) => {
    const [, searchQuery, pageNumber] = queryKey;
    if (authToken !== null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      const response = await axios.get(`${BASE_URL}/quiz/all?page=${pageNumber}&searchQuery=${searchQuery}`);
      return response.data;
    }
  };

  const {
    data: courseData,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey: ['quiz', searchQuery, pageNumber],
    queryFn: fetchCourses,
    keepPreviousData: true,
    staleTime: 300000, // 5 minutes
    cacheTime: 900000, // 15 minutes
  });

  const handlePageChange = (newPageNumber) => {
    console.log("WHAT PAGE NUMBER IS THIS >> ", newPageNumber);
    setPageNumber(newPageNumber);
    refetch();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPageNumber(1);
    refetch();
  };

  if (isLoading) {
    // return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const gradedQuizData = courseData?.data?.graded;
  const unGradedQuizData = courseData?.data?.unGraded;
  const assessmentQuizData = courseData?.data?.assessment;

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Quizzes</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Quiz</Breadcrumb.Item>
                <Breadcrumb.Item active>All</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div>
              <Link to="/dashboard/course/add-quiz" className="btn btn-primary">
                Add New Courses
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <FormControl
            type="search"
            placeholder="Search courses..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="published">
            <Card>
              <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="published" className="mb-sm-3 mb-md-0">
                      Graded
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="unpublished" className="mb-sm-3 mb-md-0">
                      Un-Graded
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="trash" className="mb-sm-3 mb-md-0">
                      Assessment
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="published" className="pb-4">
                    {console.log("SOME ISSUES >>> ", gradedQuizData?.pagination?.lastPage)}
                    <GradedQuizTable
                      courses_data={gradedQuizData}
                      lastPage={gradedQuizData?.pagination?.lastPage}
                      currentPage={gradedQuizData?.pagination?.currentPage}
                      handlePageChange={handlePageChange}
                      refreshData={refetch}
                      loading={isLoading}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="unpublished" className="pb-4">
                    <UnGradedQuizTable
                      courses_data={unGradedQuizData}
                      lastPage={unGradedQuizData?.pagination?.lastPage}
                      currentPage={unGradedQuizData?.pagination?.currentPage}
                      handlePageChange={handlePageChange}
                      refreshData={refetch}
                      loading={isLoading}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="trash" className="pb-4">
                    <AssessmentQuizTable
                      courses_data={assessmentQuizData}
                      lastPage={assessmentQuizData?.pagination?.lastPage}
                      currentPage={assessmentQuizData?.pagination?.currentPage}
                      handlePageChange={handlePageChange}
                      refreshData={refetch}
                      loading={isLoading}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllQuizes;
