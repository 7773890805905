// import node module libraries
import { useEffect, useRef } from 'react';
import { Card, Form } from 'react-bootstrap';

// import custom components
import AileadButton from 'components/marketing/common/button/AileadButton';
import { useNavigate } from 'react-router-dom';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess, quizData as dataFromQuiz } from 'helper/utils';
import { mapFormDataToQuizData } from 'data/dashboard/QuizData';
import { validateQuestions } from 'helper/utils';
import QuizCard from '../dragndrop/QuizCard';
import { useSelector } from 'react-redux';

  const QuestionsWithOptions = (props) => {
	const { previous, formData, setFormData } = props;
	const quizStorageCreateData = JSON.parse(localStorage.getItem("quizData"));
	const storageDataRef = useRef(quizStorageCreateData);
	const quizFormData = mapFormDataToQuizData(formData);
	const navigate = useNavigate();
	const {user} = useSelector(state => state.auth);

	const { postData: submitCourse,  loading, response: courseResponse } = usePostData("/quiz/create");

	const validateNext = () => {
		if (validateQuestions(formData.step2.questions, 3)) {
			submitCourse({quiz: quizFormData})
		}
	}

	useEffect(() => {
	  const submitData = async () => {
		if (courseResponse !== null && courseResponse?.status === "Success") {
			notifySuccess("Quiz Successfully created!");
			localStorage.removeItem("quizData")
			if(user.role.includes('trainer')) {
				navigate('/dashboard/trainer/quizzes');
			} else {
				navigate('/dashboard/course/quizes');
			}
		}
	  };
	  submitData();

	}, [courseResponse, storageDataRef]);  

	return (
		<>
			{/* Card */}
			<Card className="mb-3 border-0">
				<Card.Header className="border-bottom px-4 py-3">
				<h4 className="mb-0">Quiz Questions with Options</h4>
				</Card.Header>
				<QuizCard setFormData={setFormData} formData={formData} />
      		</Card>
			{/* Button */}
			<div className="d-flex justify-content-between">
				<AileadButton variant="secondary" onClick={previous} type="button" text="Previous" />
				<AileadButton variant="primary" type="button" text="Submit for Review" loading={loading} onClick={validateNext} />
			</div>
		</>
	);
};
export default QuestionsWithOptions;
