import React from 'react';
import TableWrapper from '../../common/table/TableWrapper';
import PayoutTable from './PayoutTable'; // Your existing courseTable component
import { useFetchData } from 'hooks/useFetchData';

const PayoutList = () => {
  const { data } = useFetchData('/trainer/payouts');
  const tabs = [
    { eventKey: 'all', label: 'All', component: <PayoutTable payouts={data?.data?.data || []} /> },
  ];

  return (
    <TableWrapper
      title="All Payouts"
      breadcrumbs={[
        { label: 'Home', link: '#' },
        { label: 'All', active: true },
      ]}
      buttonLink="/dashboard/trainer/payout/request"
      buttonText="Add New Payouts"
      tabs={tabs}
      defaultTabKey="all"
    />
  );
};

export default PayoutList;
