import React from 'react';
import TableWrapper from '../../common/table/TableWrapper';
import CourseTable from './CourseTable'; // Your existing courseTable component
import { useFetchData } from 'hooks/useFetchData';

const CourseList = () => {
  const { data } = useFetchData('/trainer/courses');

  const tabs = [
    { eventKey: 'all', label: 'All', component: <CourseTable courses={data?.data || []} /> },
  ];

  return (
    <TableWrapper
      title="All Courses"
      breadcrumbs={[
        { label: 'Home', link: '#' },
        { label: 'All', active: true },
      ]}
      buttonLink="/dashboard/course/add-new"
      buttonText="Add New Courses"
      tabs={tabs}
      defaultTabKey="all"
    />
  );
};

export default CourseList;
