import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tab, Card, Nav, Breadcrumb } from 'react-bootstrap';

// Reusable Page Wrapper Component
const TableWrapper = ({
  title,
  breadcrumbs,
  buttonLink,
  buttonText,
  tabs,
  defaultTabKey = 'all',
}) => {
  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">{title}</h1>
              <Breadcrumb>
                {breadcrumbs.map((breadcrumb, index) => (
                  <Breadcrumb.Item
                    key={index}
                    href={breadcrumb.link || '#'}
                    active={breadcrumb.active}
                  >
                    {breadcrumb.label}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
            {buttonText && buttonLink && (
              <div>
                <Link to={buttonLink} className="btn btn-primary">
                  {buttonText}
                </Link>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey={defaultTabKey}>
            <Card>
              <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  {tabs.map((tab, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={tab.eventKey} className="mb-sm-3 mb-md-0">
                        {tab.label}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <Tab.Content>
                  {tabs.map((tab, index) => (
                    <Tab.Pane eventKey={tab.eventKey} key={index} className="pb-4">
                      {tab.component}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TableWrapper;
